@import '../../../assets/styles/mixins.scss';

.InvoicesListTable {
	padding: 24px;
	width: 100%;
	background: var(--background-secondary, #f7f8f7);
}

.InvoicesListTable__title {
	@include heading-5;
	color: var(--body-primary, #03170d);
	margin-bottom: 16px;
}

.InvoicesListTable__searchInput {
	width: 304px;
	margin-bottom: 16px;
}

.InvoicesListTable__status {
	border-radius: 8px;
	background: var(--background-tertiary, #f2f3f2);
	padding: 4px 8px;
	color: var(--body-primary, #03170d);
	@include body-1;
	width: max-content;
	&Sent {
		background: var(--brand-tint-1, #f1fef8);
		color: var(--brand-quaternary, #0a703f);
	}
}

.InvoicesListTable__moreCell {
	width: 40px;
}

.InvoicesListTable__more {
	display: flex;
	align-items: center;

	& svg {
		cursor: pointer;
	}
}
