.PageTopPanel {
  padding: 16px 24px 16px 20px;
  border-bottom: 1px solid var(--body-separator, #eaeceb);
  background: var(--background-primary, #fff);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.PageTopPanel__title {
  color: var(--body-primary, #03170d);
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;
  letter-spacing: -0.72px;
}
