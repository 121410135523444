.Icon {
  width: 24px;
  height: 24px;
  min-width: 24px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: block;
  &.small {
    width: 16px;
    height: 16px;
    min-width: 16px;
  }
  &.big {
    width: 32px;
    min-width: 32px;
    height: 32px;
  }
}

.Icon.arrow-left {
  background-image: url('../../../../public/images/icons/arrow-left.svg');
}

.Icon.upload {
  background-image: url('../../../../public/images/icons/upload.svg');
}

.Icon.calendar {
  background-image: url('../../../../public/images/icons/calendar.svg');
}

.Icon.plus {
  background-image: url('../../../../public/images/icons/plus.svg');
}

.Icon.delete {
  background-image: url('../../../../public/images/icons/delete.svg');
}

.Icon.arrow-up {
  background-image: url('../../../../public/images/icons/up.svg');
}

.Icon.arrow-down {
  background-image: url('../../../../public/images/icons/down.svg');
}

.Icon.arrow-right {
  background-image: url('../../../../public/images/icons/right.svg');
}

.Icon.more-icon {
  background-image: url('../../../../public/images/icons/more.svg');
}

.Icon.plus-white {
  background-image: url('../../../../public/images/icons/plus-white.svg');
}

.Icon.folder {
  background-image: url('../../../../public/images/icons/folder.svg');
}

.Icon.search {
  background-image: url('../../../../public/images/icons/search.svg');
}

.Icon.duplicate {
  background-image: url('../../../../public/images/icons/duplicate.svg');
}
