@import '../mixins.scss';

.Button {
  padding: 12px 16px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  background: transparent;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  line-height: 24px;

  width: max-content;
  justify-content: center;
  color: var(--brand-quaternary, #0a703f);
  transition: 0.1s linear all;
  @include subhead-1;

  &.primary {
    background: var(--brand-primary, #13cb72);
    color: var(--body-ontint, #fff);
    &:hover {
      background: var(--brand-tertiary, #0f9f59);
    }
  }
  &.secondary {
    background: var(--brand-alpha-1, rgba(19, 203, 114, 0.1));
    color: var(--brand-quaternary, #0a703f);
  }

  &.red {
    background: var(--error-tint-1, #fdf2f2);
    &:hover {
      background: #ff7979;
    }
  }

  &:hover {
    background: var(--brand-alpha-2, rgba(19, 203, 114, 0.2));
  }

  &.disabled {
    background: #a1aaa6;
    color: var(--body-tertiary, #3b4540);
    cursor: default;
  }

  .Icon {
    margin-right: 8px;
  }
}
