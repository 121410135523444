@import '../assets/styles/mixins.scss';

.ContextMenuItem {
	display: flex;
	flex-direction: row;
	gap: 8px;

	padding: 8px;

	border-radius: 8px;

	cursor: pointer;
	user-select: none;

	white-space: nowrap;
}

.ContextMenuItem:hover {
	background: var(--background-tertiary, #F2F3F2);
}

.ContextMenuItem:active {
	background: var(--background-quaternary, #E4E7E6);
}

.ContextMenuItem__text {
	@include body-1;
}
