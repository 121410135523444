@import '../mixins.scss';

.Header {
  padding: 0 16px;
  border-bottom: 1px solid var(--body-separator, #eaeceb);
  background: var(--background-primary, #fff);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
}

.Header__left {
  display: flex;
  align-items: center;
}

.Header__logo {
  background-image: url('../../../../public/images/logo.svg');
  background-repeat: no-repeat;
  background-size: cover;
  width: 48px;
  height: 48px;
  margin-right: 32px;
}

.Header__menu {
  display: flex;
  column-gap: 24px;
  align-items: center;
  padding-top: 30px;
}

.Header__menu__item {
  @include subhead-1;
  padding-bottom: 24px;
  color: var(--body-primary, #03170d);

  cursor: pointer;
  &:hover {
    color: var(--brand-tertiary, #0f9f59);
  }
  &.active {
    color: var(--brand-primary, #13cb72);
    border-bottom: 2px solid var(--brand-primary, #13cb72);
  }
}

.Header__right {
  display: flex;
  align-items: center;
  column-gap: 16px;
}

.Header__plan {
  padding: 8px 16px;
  display: flex;
  align-items: center;
  width: 348px;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid var(--body-separator, #eaeceb);
}

.Header__plan__name {
  .label {
    color: var(--body-quaternary, #6d7873);
    @include body-3;
  }
  .name {
    color: var(--body-primary, #03170d);
    @include body-1;
  }
}

.Header__plan__right {
  display: flex;
  align-items: center;
  cursor: pointer;
  column-gap: 8px;
  .left-invoices {
    color: var(--body-primary, #03170d);
    @include subhead-1;
  }
  .upgrade-label {
    padding: 4px 8px;
    border-radius: 8px;
    background: var(--brand-primary, #13cd73);
    color: var(--body-ontint, #fff);
    @include subhead-3;
  }
}

.Header__userInfo {
  padding: 20px 16px;
  display: flex;
  align-items: center;
  column-gap: 16px;
  cursor: pointer;
}

.Header__userInfo__avatar {
  background: #ecddff;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  color: var(--avatar-9-accent, #641ebb);
  @include subhead-1;
}

.Header__userInfo__info {
  .fullName {
    color: var(--body-primary, #03170d);
    @include subhead-1;
  }
  .mail {
    color: var(--body-quaternary, #6d7873);
    @include body-3;
  }
}
