@import '../mixins.scss';

.TextArea {
  flex: 1;
  textarea {
    padding: 12px 16px;
    border-radius: 8px;
    border: 1px solid var(--body-separator, #eaeceb);
    background: var(--background-primary, #fff);
    color: var(--body-primary, #03170d);
    @include body-1;
    width: 100%;
    font-family: 'Manrope';
    resize: none;
  }
}
.TextArea__label {
  @include body-3;
  color: var(--body-quaternary, #6d7873);
  margin-bottom: 4px;
}
