@import '../mixins.scss';

.Select {
  position: relative;
  width: max-content;
}

.SelectInput {
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid var(--body-separator, #eaeceb);
  background: var(--background-primary, #fff);
  color: var(--body-primary, #03170d);
  @include body-1;
  display: flex;
  align-items: center;
  cursor: pointer;
  .Icon {
    margin-left: 14px;
  }
}

.Select__options {
  position: absolute;
  background: #ffffff;
  width: 100%;
  border: 1px solid var(--body-separator, #eaeceb);
}

.Select__option {
  text-align: right;
  padding: 6px;
  border-bottom: 1px solid var(--body-separator, #eaeceb);
  cursor: pointer;

  &:last-of-type {
    border-bottom: none;
  }
}
