@import '../mixins.scss';

.Datepicker {
  flex: 1;
}
.Datepicker__label {
  @include body-3;
  color: var(--body-quaternary, #6d7873);
  margin-bottom: 4px;
}
.Datepicker__container {
  position: relative;
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid var(--body-separator, #eaeceb);
  background: var(--background-primary, #fff);
  color: var(--body-primary, #03170d);
  @include body-1;
  width: 100%;
  font-family: 'Manrope';
  display: flex;
  align-items: center;
  column-gap: 8px;
  input {
    @include body-1;
    border: none;
    outline: none;
  }
}
