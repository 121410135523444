@mixin heading-1 {
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 68px;
  letter-spacing: -1.92px;
}

@mixin heading-2 {
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: -1.6px;
}
@mixin heading-3 {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -1.28px;
}

@mixin heading-4 {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.96px;
}

@mixin heading-5 {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: -0.8px;
}

@mixin body-1 {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.32px;
}

@mixin body-2 {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.28px;
}

@mixin body-3 {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.24px;
}

@mixin subhead-1 {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.32px;
}

@mixin subhead-2 {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.28px;
}

@mixin subhead-3 {
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: -0.24px;
}

@mixin label-2 {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.56px;
  text-transform: uppercase;
}
