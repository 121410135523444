:root {
  --background-primary: #fff;
  --body-ontint: #fff;
  --body-separator: #eaeceb;
  --body-primary: #03170d;
  --body-tertiary: #3b4540;
  --body-quaternary: #6d7873;
  --brand-primary: #13cb72;
  --brand-tertiary: #0f9f59;
  --brand-quaternary: #0a703f;
  --brand-alpha-1: rgba(19, 203, 114, 0.1);
  --brand-alpha-2: #13cb72;
  --error-tint-1: #fdf2f2;
  --overlay: rgba(0, 0, 0, 0.8);
}
