@import '../mixins.scss';

.Table__container {
  width: 100%;
  border-radius: 16px;
  border: 1px solid var(--body-separator, #eaeceb);
  background: var(--background-primary, #fff);
}

.Table {
  width: 100%;
}

.Table th {
  text-align: start;
  padding: 12px 0px;
  color: var(--body-tertiary, #3b4540);
  @include subhead-1;
  border-bottom: 1px solid var(--body-separator, #eaeceb);
  &:first-of-type {
    padding-left: 16px;
  }
}

.Table td {
  padding: 12px 0px;
  border-bottom: 1px solid var(--body-separator, #eaeceb);
  color: var(--body-primary, #03170d);
  @include body-1;
  &:first-of-type {
    padding-left: 16px;
  }
}

.Table__footer {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  justify-content: space-between;
}

.Table__footer__rows-per-page {
  display: flex;
  align-items: center;
  color: var(--body-primary, #03170d);
  @include body-1;
  column-gap: 8px;
}

.Table__actionButtonsContainer {
  width: 100%;
  display: flex;
  padding-right: 20px;
  justify-content: flex-end;
}
