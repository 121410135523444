@import '../mixins.scss';

.FileInput__label {
  @include body-3;
  color: var(--body-quaternary, #6d7873);
  margin-bottom: 4px;
}

.FileInput {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.FileInput__input {
  border-radius: 8px;
  border: 1px dashed var(--body-separator, #eaeceb);
  padding: 16px;
  display: flex;
  align-items: center;
  column-gap: 8px;
  cursor: pointer;
  input {
    display: none;
  }
}

.FileInput__input__label {
  @include subhead-1;
  color: var(--body-primary, #03170d);
}

.FileInput__input__description {
  @include body-3;
  color: var(--body-tertiary, #3b4540);
}

.FileInput__preview {
  border-radius: 8px;
  border: 1px solid var(--body-separator, #eaeceb);
  height: 72px;
  max-width: 250px;
  display: flex;
  align-items: center;
  column-gap: 16px;
  padding: 6px 24px;
}

.FileInput__preview img {
  max-height: 100%;
  max-width: calc(100% - 24px);
}

.FileInput__preview .Icon {
  cursor: pointer;
}
