@import '../mixins.scss';

.InvoiceTopPanel {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 16px 24px 16px 20px;
  border-bottom: 1px solid var(--body-separator, #eaeceb);
  background: var(--background-primary, #fff);
}

.InvoiceTopPanel__backButton {
  display: flex;
  align-items: center;
}

.InvoiceTopPanel__backButton__label {
  color: var(--body-quaternary, #6d7873);
  @include subhead-2;
}

.InvoiceTopPanel__title {
  margin-top: 4px;
  color: var(--body-primary, #03170d);
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 32px;
  letter-spacing: -0.72px;
}

.InvoiceTopPanel__right {
  display: flex;
  column-gap: 16px;
  align-items: center;
}

.InvoicePage__content {
  width: 100%;
  display: flex;
  background: var(--background-secondary, #f7f8f7);

  > div {
    flex: 1;
  }
}

.rpv-core__inner-page {
  background: var(--background-secondary, #f7f8f7);
}

.InvoiceForm {
  padding: 24px;
  background: #ffffff;
}

.InvoiceForm__title {
  @include subhead-1;
  color: var(--body-tertiary, #3b4540);
  margin-bottom: 24px;
}

.InvoiceForm__row {
  margin-bottom: 24px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  column-gap: 16px;
}

.InvoiceItems {
  border-bottom: 1px solid var(--body-separator, #eaeceb);
  padding-bottom: 16px;
  width: 100%;
}

.InvoiceItems__title {
  color: var(--body-tertiary, #3b4540);
  @include subhead-1;
  margin-bottom: 8px;
}

.InvoiceItems__list {
  margin-bottom: 16px;
}

.InvoiceItem {
  margin-bottom: 8px;
  border-radius: 16px;
  padding: 16px;
  border: 1px solid var(--body-separator, #eaeceb);
  display: flex;
  align-items: flex-end;
  column-gap: 16px;
  &:last-of-type {
    margin-bottom: 0;
  }
}

.InvoiceItem__place {
}

.InvoiceItem__place__arrow {
  border-radius: 4px;
  background: var(--brand-alpha-1, rgba(19, 203, 114, 0.1));
  padding: 1px 4px;
  cursor: pointer;
}

.InvoiceItem__place__place {
  border-radius: 8px;
  border: 1px solid var(--body-separator, #eaeceb);
  padding: 4px 0px;
  color: var(--body-primary, #03170d);
  text-align: center;
  margin: 4px 0;
  @include subhead-3;
}

.InvoiceTotals {
}

.InvoiceTotals__row {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 16px;
  .Input {
    max-width: 200px;
  }
  margin-bottom: 16px;
}

.InvoiceTotals__label {
  color: var(--body-quaternary, #6d7873);
  @include body-1;
}

.InvoiceTotals__value {
  color: var(--body-primary, #03170d);
  @include body-1;
  min-width: 100px;
  text-align: right;
  &.total {
    @include subhead-1;
  }
}
