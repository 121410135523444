@import '../mixins.scss';

.TemplatesPage__content {
  width: 100%;
  background: var(--background-secondary, #f7f8f7);
  padding: 24px;
  min-height: calc(100vh - 165px);
}

.TemplatesListTable__searchInput {
  margin-bottom: 16px;
  width: 304px;
}

.TemplatesListTable__useButton {
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 4px 8px;
  color: var(--body-primary, #03170d);
  border: 1px solid var(--body-separator, #eaeceb);
  width: max-content;
  cursor: pointer;
  column-gap: 4px;
  @include subhead-3;
}
