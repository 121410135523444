@import '../mixins.scss';

.Input {
  flex: 1;
}
.Input__label {
  @include body-3;
  color: var(--body-quaternary, #6d7873);
  margin-bottom: 4px;
}
.Input__container {
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid var(--body-separator, #eaeceb);
  background: var(--background-primary, #fff);
  color: var(--body-primary, #03170d);
  @include body-1;
  width: 100%;
  font-family: 'Manrope';
  display: flex;
  align-items: center;
  input {
    border: none;
    outline: none;
    padding: 0;
    color: var(--body-primary, #03170d);
    @include body-1;
    width: 100%;
    font-family: 'Manrope';
  }
  span,
  .Icon {
    margin-right: 4px;
  }
}
.Input.disabled {
  .Input__container {
    background: var(--body-separator, #f2f3f2);
  }
}
