@import '../mixins.scss';

.InvoicesPage__content {
  width: 100%;
  display: flex;
}

.InvoicesPageLeftPanel {
  min-width: 320px;
  max-width: 320px;
  border-right: 1px solid var(--body-separator, #eaeceb);
  background: var(--background-primary, #fff);
  padding: 24px;
  height: calc(100vh - 165px);
}

.InvoicesPageLeftPanel__tabs {
  border-bottom: 1px solid var(--body-separator, #eaeceb);
  padding-bottom: 16px;
}

.InvoicesPageLeftPanel__tab {
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  column-gap: 4px;
  cursor: pointer;
  padding: 8px;
  &:last-of-type {
    margin-bottom: 0;
  }
}

.InvoicesPageLeftPanel__tab__name {
  color: var(--body-primary, #03170d);
  @include body-1;
}

.InvoicesPageLeftPanel__tab.active {
  border-radius: 8px;
  background: var(--brand-primary, #13cd73);
  .InvoicesPageLeftPanel__tab__name {
    color: var(--body-ontint, #fff);
    @include subhead-1;
  }
}

.InvoicesPageLeftPanel__folders__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 22px;
}

.InvoicesPageLeftPanel__folders__head__title {
  @include label-2;
  color: var(--body-tertiary, #3b4540);
}

.InvoicesPageFolders__folder {
  &.child-1 {
    .InvoicesPageFolders__folder__item {
      padding-left: 24px;
    }
  }
  &.child-2 {
    .InvoicesPageFolders__folder__item {
      padding-left: 60px;
    }
  }
}

.InvoicesPageFolders__folder__item {
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  padding: 8px;
  column-gap: 4px;
  &.active {
    background: var(--brand-primary, #13cd73);
    border-radius: 8px;
    .InvoicesPageFolders__folder__name {
      color: #ffffff;
    }
  }
}

.InvoicesPageFolders__folder__name {
  color: var(--body-primary, #03170d);
  @include body-1;
}

.InvoicesPageFolders__folder__icon-arrow-right {
  transform: rotate(270deg);
}
