@import './vars.scss';
@import './mixins.scss';

@import './components/button.scss';
@import './components/header.scss';
@import './components/icons.scss';
@import './components/file-input.scss';
@import './components/textarea.scss';
@import './components/input.scss';
@import './components/datepicker.scss';
@import './components/page-top-panel.scss';
@import './components/table.scss';
@import './components/select.scss';

@import './pages/invoice-page.scss';
@import './pages/invoices-page.scss';
@import './pages/templates-page.scss';

@font-face {
  font-family: 'Manrope';
  src: url('../fonts/Manrope-VariableFont_wght.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Manrope', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  background: var(--background-primary, #fff);
  color: var(--body-primary, #03170d);

  * {
    box-sizing: border-box;
  }
  a {
    text-decoration: none;
  }
}

html {
  scroll-behavior: smooth;
}
