@import '../assets/styles/mixins.scss';

.ContextMenu {
	display: flex;
	flex-direction: column;
	gap: 4px;

	padding: 8px;

	border-radius: 16px;
	border: 1px solid var(--body-separator, #EAECEB);
	background: var(--background-primary, #FFF);
	box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05);
}
